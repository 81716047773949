.red-slider-circle {
    outline: 1px solid black;
    background-color: red;
    height: 30px;
    width: 30px;
    justify-self: center;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
}

.bounds {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    outline: 1px solid black;
}